<template>
  <div class="" v-if="creditNote">
    <TopBarTitleComponent
      title="Información Nota de Crédito"
      :showBackIcon="true"
    >
      <template slot="right-area">
        <div class="d-flex justify-content-end align-items-center">
          <el-dropdown
            @command="handleCommand"
            class="dropdown-theme-gray-outline d-flex align-items-center mr-4"
          >
            <span class="el-dropdown-link">
              <span class="ml-2">Opciones</span>
              <i class="el-icon-arrow-down el-icon--right mr-2"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <template
                v-if="
                  creditNote.status === 'draft' ||
                    creditNote.status === 'failed'
                "
              >
                <el-dropdown-item command="credit-note-edit">
                  <img :src="iconEdit" alt="" /> Editar</el-dropdown-item
                >
              </template>

              <el-dropdown-item command="credit-note-send">
                <img :src="iconSend" alt="" /> Enviar
                documento</el-dropdown-item
              >
              <template>
                <el-dropdown-item
                  v-if="creditNote.status == 'success'"
                  command="credit-note-nuled"
                  ><img :src="iconNuled" alt="" /> Anular</el-dropdown-item
                >
              </template>
              <el-dropdown-item command="credit-note-download"
                ><img :src="iconDownload" alt="" /> Descargar
                PDF</el-dropdown-item
              >
              <el-dropdown-item
                v-if="
                  creditNote.status !== 'success' &&
                    creditNote.status !== 'nuled'
                "
                command="credit-note-delete"
              >
                <img :src="iconDelete" alt="" /> Eliminar
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <ButtonPrimaryComponent
            v-if="
              creditNote.status !== 'success' && creditNote.status !== 'nuled'
            "
            title="Firmar"
            @onSaveClick="toogleModalDocumentSRI"
          />
        </div>
      </template>
    </TopBarTitleComponent>
    <b-container fluid class="pb-4 px-lg-4 pt-4">
      <b-row v-if="creditNote.sri_sign_message">
        <b-col>
          <AlertInfoComponent
            title="Notificación SRI"
            type="error"
            class="mb-4"
            :description="creditNote.sri_sign_message"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="card box-shadow card-quotes-view py-4">
            <div class="card-body">
              <div class="card-quotes-view-header">
                <b-row>
                  <b-col xs="12" sm="12" md="12" lg="6">
                    <div class="card-quotes-view-logo mb-4">
                      <b-row>
                        <b-col xs="12" sm="12" md="5" lg="4">
                          <div class="logo">
                            <img
                              v-if="getUser.user_business.business_logo"
                              :src="getUser.user_business.business_logo"
                              class="image"
                              id="image-business"
                              alt="Empresa"
                              width="200px"
                            />
                          </div>
                        </b-col>
                        <b-col xs="12" sm="12" md="7" lg="8">
                          <div class="info">
                            <h4 class="h4 font-bold m-0">
                              N° {{ creditNote.identification_document }}
                            </h4>
                            <h2 class="h2 font-bold m-0"></h2>
                            <p class="font-semi-bold mt-2">
                              RUC: {{ getUser.user_business.business_ruc }}
                            </p>
                            <p class="font-semi-bold">
                              {{ getUser.user_business.business_address }}
                            </p>

                            <SignAuthorizationInfo
                              class="mt-4"
                              :accessKey="creditNote.access_key"
                              :signMode="creditNote.sign_mode"
                            />
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                  <b-col xs="12" sm="12" md="12" lg="6">
                    <div class="card-quotes-view-info">
                      <b-row>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block"
                            >Fecha de emisión</label
                          >
                          <p class="m-0">
                            {{ creditNote.emission_date_for_view }}
                          </p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block"
                            >Documento modificar</label
                          >
                          <p class="m-0">
                            {{ creditNote.invoice.number_invoice }}
                          </p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block mb-2"
                            >Status</label
                          >
                          <StatusReadDocumentsComponent
                            :status.sync="creditNote.status"
                          />
                        </b-col>
                      </b-row>
                      <b-row class="mt-4">
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block">Cliente</label>
                          <p class="m-0">
                            {{ creditNote.invoice.client.name }}
                          </p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block"
                            >Documento</label
                          >
                          <p class="m-0">
                            {{ creditNote.invoice.client.document }}
                          </p>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4">
                          <label class="font-semi-bold d-block">Correo</label>
                          <p class="m-0">
                            {{ creditNote.invoice.client.email }}
                          </p>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <div class="card-quotes-view-body mt-5">
                <div class="card-quotes-view-body-products">
                  <div class="card-quotes-view-table table-items">
                    <b-row class="table-items-header">
                      <b-col xs="12" sm="12" md="1">
                        <h6 class="h6 font-bold mb-0 ml-2">Código</h6>
                      </b-col>
                      <b-col xs="12" sm="12" md="5">
                        <h6 class="h6 font-bold mb-0">Detalle</h6>
                      </b-col>
                      <b-col xs="12" sm="4" md="2">
                        <h6 class="h6 font-bold mb-0">Cantidad</h6>
                      </b-col>
                      <b-col xs="12" sm="4" md="2">
                        <h6 class="h6 font-bold mb-0">Precio U.</h6>
                      </b-col>
                      <b-col xs="12" sm="4" md="2">
                        <h6 class="h6 font-bold mb-0">Subtotal</h6>
                      </b-col>
                    </b-row>
                  </div>

                  <b-row
                    class="mt-4 d-flex"
                    v-for="(product, index) in creditNote.products"
                    :key="index"
                  >
                    <b-col xs="12" sm="12" md="1">
                      <p class="m-0 ml-2">{{ product.product.code }}</p>
                    </b-col>
                    <b-col xs="12" sm="12" md="5">
                      <p class="m-0">
                        <strong>{{ product.product.name }}</strong> <br />
                        <br />
                        <span
                          v-html="product.product_detail"
                          class="text-pre-wrap"
                        ></span>
                      </p>
                    </b-col>
                    <b-col xs="12" sm="4" md="2">
                      <p class="m-0">{{ product.product_quantity }}</p>
                    </b-col>
                    <b-col xs="12" sm="4" md="2">
                      <p class="m-0">${{ product.product_amount }}</p>
                    </b-col>
                    <b-col xs="12" sm="4" md="2">
                      <p class="m-0">
                        ${{
                          handleCalculateSubTotal(
                            product.product_quantity,
                            product.product_amount
                          )
                        }}
                      </p>
                    </b-col>
                  </b-row>
                </div>
                <hr />
                <b-row>
                  <b-col xs="12" sm="12" md="6"></b-col>
                  <b-col xs="12" sm="12" md="4" offset-md="2">
                    <DocumentCalculatorNotes
                      :setProducts="creditNote.products"
                    />
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <el-dialog
      width="35%"
      title=""
      :visible.sync="creditNoteSRIOuterVisible"
      class=""
    >
      <div class="wrapper__invoice-signature">
        <h4 class="h4 text-center font-semi-bold text-break text-color-primary">
          ¿Desea firmar y enviar el documento al SRI?
        </h4>
        <div class="d-flex justify-content-center align-items-center mt-5">
          <button type="button" class="btn md__btn-third-secondary mr-4">
            Cancelar
          </button>
          <ButtonPrimaryComponent
            title="Confirmar"
            @onSaveClick="sendCreditNoteSRI"
          />
        </div>
      </div>
    </el-dialog>

    <el-dialog
      width="35%"
      title=""
      :visible.sync="creditNoteDeleteOuterVisible"
      class=""
    >
      <div class="wrapper__invoice-delete text-center">
        <img :src="icoNotifyDanger" alt="ico danger" width="30" />
        <h4 class="h4 font-semi-bold text-break text-color-primary mt-4">
          ¿Seguro desea eliminar la nota de crédito?
        </h4>

        <p class="text-color-primary text-break">
          Recuerde que solo puede eliminar las notas de crédito en borrador.
        </p>

        <div class="d-flex justify-content-center align-items-center mt-4">
          <button
            @click="toggleModalCreditNoteDelete"
            class="btn md__btn-secondary mr-4"
          >
            Cancelar
          </button>
          <ButtonPrimaryComponent
            title="Eliminar"
            @onSaveClick="onCreditNoteDelete"
          />
        </div>
      </div>
    </el-dialog>

    <el-dialog
      width="35%"
      title=""
      :visible.sync="creditNoteNuledOuterVisible"
      class=""
    >
      <div class="wrapper__invoice-nuled text-center">
        <img :src="icoNotifyDanger" alt="ico danger" width="30" />
        <h4 class="h4 font-semi-bold text-break text-color-primary mt-4">
          ¿Seguro desea anular la nota de crédito?
        </h4>

        <p class="text-color-primary">
          Para completar todo el proceso de anulación la nota de crédito debe
          ser anulada en el
          <LinkSRI />
        </p>

        <div class="d-flex justify-content-center align-items-center mt-4">
          <button
            @click="toggleModalCreditNoteNuled"
            class="btn md__btn-secondary mr-4"
          >
            Cancelar
          </button>
          <ButtonPrimaryComponent
            title="Anular"
            @onSaveClick="onCreditNoteNuled"
          />
        </div>
      </div>
    </el-dialog>

    <WrapperSendDocumentDrawer
      @onDocumentSend="onDocumentSend"
      @onCloseDrawer="onCloseDocumentSendDrawer"
      :visibleDocumentSend="visibleDocumentSend"
      :toMails="toMails"
      :isLoadingConfigTexts="false"
    />
  </div>
</template>

<script>
import AlertInfoComponent from "@/components/Alerts/Info";
import TopBarTitleComponent from "../../../../components/TopBarTitle";
import creditNoteService from "../services/creditNoteService";
import ButtonPrimaryComponent from "../../../../components/ButtonPrimary";
import StatusReadDocumentsComponent from "../../../../components/StatusReadDocuments";
import SignAuthorizationInfo from "../../../components/SignAuthorizationInfo";
import DocumentCalculatorNotes from "../../../components/DocumentCalculatorNotes";
import WrapperSendDocumentDrawer from "../../../../components/WrapperSendDocumentDrawer";
import LinkSRI from "@/components/General/LinkSRI";

import { parseFloatFormat } from "@/assets/utils/format";
import { downloadFile } from "@/assets/utils/files";
import {
  icoNotifyDanger,
  iconEdit,
  iconSend,
  iconDownload,
  iconDelete,
  iconNuled
} from "@/services/resources";

export default {
  name: "CreditNoteViewPage",
  data: () => ({
    icoNotifyDanger,
    iconDelete,
    iconNuled,
    iconEdit,
    iconSend,
    iconDownload,
    creditNoteSRIOuterVisible: false,
    creditNoteDeleteOuterVisible: false,
    creditNoteNuledOuterVisible: false,
    creditNote: null,
    visibleDocumentSend: false
  }),
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    },
    toMails() {
      const { client } = this.creditNote.invoice;
      if (client && client.email) return [client.email];
      return [];
    }
  },
  methods: {
    toggleModalCreditNoteNuled() {
      this.creditNoteNuledOuterVisible = !this.creditNoteNuledOuterVisible;
    },
    handleCalculateSubTotal(quantity, price) {
      return parseFloatFormat(quantity * price);
    },
    goPage(name) {
      this.$router.push({ name });
    },
    toogleModalDocumentSRI() {
      this.creditNoteSRIOuterVisible = !this.creditNoteSRIOuterVisible;
    },
    async onCreditNoteNuled() {
      try {
        this.$store.dispatch("toggleRequestLoading");
        const { id: creditNoteId } = this.creditNote;
        let response = await creditNoteService
          .updateCreditNoteStatusNuled({
            user_business_credit_note_id: creditNoteId
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
            this.toggleModalCreditNoteNuled();
          });
        const { success, data, message } = response.data;
        if (success) {
          this.creditNote = data;
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    async sendCreditNoteSRI() {
      this.$store.dispatch("toggleRequestLoading", {
        text:
          "Por favor espere un momento, su documento esta siendo enviado al SRI."
      });
      try {
        this.toogleModalDocumentSRI();
        let response = await creditNoteService
          .sendCreditNoteSRI({
            user_business_credit_note_id: this.creditNote.id
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message, data } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
        } else {
          this.$notifications.error({
            message
          });
        }
        this.creditNote = data;
      } catch (error) {
        return false;
      }
    },
    async getCreditNoteId(creditNoteId) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await creditNoteService
          .getCreditNoteId(creditNoteId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, data } = response.data;
        if (success) {
          this.creditNote = data;
        }
      } catch (error) {
        return false;
      }
    },
    async onCreditNoteDelete() {
      try {
        this.$store.dispatch("toggleRequestLoading");
        const { id: creditNoteId } = this.creditNote;
        let response = await creditNoteService
          .deleteCreditNoteId(creditNoteId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
            this.toggleModalCreditNoteDelete();
          });
        const { success, message } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
          this.goPage("sales.documents");
        }
      } catch (error) {
        return false;
      }
    },
    handleCommand(command) {
      switch (command) {
        case "credit-note-edit":
          this.goPage("sales.creditNote.edit", {
            id: this.creditNote.id
          });
          break;
        case "credit-note-send":
          this.visibleDocumentSend = true;
          break;
        case "credit-note-nuled":
          this.toggleModalCreditNoteNuled();
          break;
        case "credit-note-delete":
          this.toggleModalCreditNoteDelete();
          break;
        case "credit-note-download":
          this.creditNoteDownload();
          break;
      }
    },
    toggleModalCreditNoteDelete() {
      this.creditNoteDeleteOuterVisible = !this.creditNoteDeleteOuterVisible;
    },
    onDocumentSend({ data }) {
      data.append("credit_note_id", this.creditNote.id);

      this.sendCreditNoteMail({ data });
    },
    async sendCreditNoteMail({ data }) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await creditNoteService
          .sendCreditNoteMail({
            data
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.visibleDocumentSend = false;
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    async creditNoteDownload() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        const { identification_document, id } = this.creditNote;
        let response = await creditNoteService
          .creditNoteDownload({
            id
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { data } = response;
        if (data) {
          downloadFile(data, `NotaCredito - #${identification_document}`);
        }
      } catch (error) {
        return false;
      }
    },
    onCloseDocumentSendDrawer() {
      this.visibleDocumentSend = false;
    }
  },
  components: {
    TopBarTitleComponent,
    AlertInfoComponent,
    ButtonPrimaryComponent,
    StatusReadDocumentsComponent,
    SignAuthorizationInfo,
    DocumentCalculatorNotes,
    WrapperSendDocumentDrawer,
    LinkSRI
  },
  mounted() {
    const { id: creditNoteId } = this.$route.params;
    if (creditNoteId) {
      this.getCreditNoteId(creditNoteId);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-quotes-view {
  .card-quotes-view-header {
    .card-quotes-view-logo {
      p {
        margin: 0;
      }
    }
  }
  .card-quotes-view-body-products {
    min-height: 300px;
  }
}
</style>
